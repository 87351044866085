import { useState, useEffect } from 'react';
import { Empty, DatePicker, Tooltip, Card, Button, Space, Spin, Row, Col, Popconfirm } from 'antd';
import { useAxios } from '../../../util/AxiosUtil';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from "react-i18next";
import { getStep, getDateFormat, getDispDateFormat, getMinutesPart, getDateRange } from '../../../util/ChartUtil';

import dayjs from 'dayjs';
import { Column, DualAxes } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';

import { LeftOutlined, RightOutlined, DownloadOutlined, SyncOutlined, PlusOutlined, MinusOutlined, CloseOutlined } from '@ant-design/icons';

const BatteryControlChart = ({ batteryId, refresh, setRefresh, dateRange, changeDateRange }) => {

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  const { keycloak } = useKeycloak();
  const dateFormatDisp = "DD.MM.YYYY HH:ss";

  const { i18n, t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const [graphData, setGraphData] = useState([]);
  const [priceGraphData, setPriceGraphData] = useState([]);
  const [emptyGraphData, setEmptyGraphData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [groupBy, setGroupBy] = useState("hour");

  useEffect(() => {
    customFetch(dateRange[1], dateRange[1]);
  }, [batteryId, dateRange, refresh, t]);

  useEffect(() => {
    function handleResize() {
        setIsMobile(window.innerWidth < 700)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  const customFetch = async (dateFrom, dateTo) => {
    
      setIsLoading(true);
      try {
        const priceEe = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/okte/electricity/'+dateFrom.format(getDateFormat(groupBy)));
        const batteryAction = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+batteryId+'/BATTERY/battery_action/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));
        const chargingCost = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+batteryId+'/BATTERY/charging_cost/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));
        const chargingNet = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+batteryId+'/BATTERY/charging_network/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));

        const dischargingCost = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+batteryId+'/BATTERY/discharging_cost/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));
        const dischargingNet = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+batteryId+'/BATTERY/discharging_network/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));
        const chargeState = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+batteryId+'/BATTERY/charge_state/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));

        var batteryActionData = [];
        var chargingCostData = [];
        var dischargingCostData = [];
        var chargeStateData = [];
        for (var k = 0; k < batteryAction.data.length; k++) {
          if (batteryAction.data[k].value !== null) {
            batteryActionData.push({
              value: batteryAction.data[k].value == 1 ? t("page.charging") : batteryAction.data[k].value == 2 ? t("page.discharging") : "Stop",
              type: t("Prevádzka"),
              date: dayjs(batteryAction.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy)
            });

            for (var l = 0; l < chargingCost.data.length; l++) {
              if (dayjs(batteryAction.data[k].date).format("DD.MM.YYYY HH:mm") === dayjs(chargingCost.data[l].date).subtract(1,'hour').format("DD.MM.YYYY HH:mm")) {

                for (var m = 0; m < chargingNet.data.length; m++) {
                  if (dayjs(batteryAction.data[k].date).format("DD.MM.YYYY HH:mm") === dayjs(chargingNet.data[m].date).subtract(1,'hour').format("DD.MM.YYYY HH:mm")) {
                    let charging = batteryAction.data[k].value === 1 ? 1 : 0;

                    chargingCostData.push({
                      value: charging * (priceEe.data[k].price * chargingNet.data[m].value) + chargingCost.data[l].value,
                      type: t("page.charging_cost"),
                      date: dayjs(batteryAction.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy)
                    });
                  }
                }
              }
            }

            for (var l = 0; l < dischargingCost.data.length; l++) {
              if (dayjs(batteryAction.data[k].date).format("DD.MM.YYYY HH:mm") === dayjs(dischargingCost.data[l].date).format("DD.MM.YYYY HH:mm")) {

                for (var m = 0; m < dischargingNet.data.length; m++) {
                  if (dayjs(batteryAction.data[k].date).format("DD.MM.YYYY HH:mm") === dayjs(dischargingNet.data[m].date).subtract(1,'hour').format("DD.MM.YYYY HH:mm")) {
                    let discharging = batteryAction.data[k].value === 2 ? 1 : 0;


                    console.log('++++++++++++++++');
                    console.log(dayjs(batteryAction.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy));
                    console.log("discharging net" + dayjs(dischargingNet.data[m].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy) + " - " + dischargingNet.data[m].value);
                    console.log(priceEe.data[k].price);
                    console.log(dischargingCost.data[l].value);
                    console.log("----------------------------------");
    
                    dischargingCostData.push({
                      value: discharging * dischargingCost.data[l].value * dischargingNet.data[m].value,
                      type: t("page.discharge_revenue"),
                      date: dayjs(batteryAction.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy)
                    });
                  }
                }
              }
            }

            for (var l = 0; l < chargeState.data.length; l++) {
              if (dayjs(batteryAction.data[k].date).format("DD.MM.YYYY HH:mm") === dayjs(chargeState.data[l].date).format("DD.MM.YYYY HH:mm")) {

                chargeStateData.push({
                  value: chargeState.data[l].value,
                  type: t("page.charge_state"),
                  date: dayjs(batteryAction.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy)
                });
              }
            }

          }
        }
        
        var priceEeData = [];
        for (var k = 0; k < priceEe.data.length; k++) {
          var period = priceEe.data[k].period === '24' ? '00' : priceEe.data[k].period;
          priceEeData.push({
            value: parseFloat(priceEe.data[k].price),
            date: dayjs(priceEe.data[k].bday+' '+period).format(dateFormatDisp),
            type: t("page.price")
          });
        }

        var finalData = chargingCostData.concat(dischargingCostData).concat(batteryActionData);

        setEmptyGraphData(false);
        if (finalData.length === 0)
          setEmptyGraphData(true);

        setGraphData(finalData);
        setPriceGraphData(priceEeData.concat(chargeStateData));

        setIsLoading(false);
      } catch(err) {
        console.log(err);
      }
  };

  const prevStep = () => {
    changeDateRange([dayjs(dateRange[0]).subtract(1,'d'), dayjs(dateRange[1]).subtract(1,'d')]);
  }
  
  const nextStep = () => {
    if (!dayjs(dateRange[1]).isSame(dayjs())) {
      changeDateRange([dayjs(dateRange[0]).add(1,'d'), dayjs(dateRange[1]).add(1,'d')]);
    } else {
      customFetch(dateRange[1], dateRange[1]);
    }
  }

  const refreshChart = () => {
    customFetch(dateRange[1], dateRange[1]);
    setRefresh();
  }

  const chargeBattery = async () => {
    setIsLoading(true);
    await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/battery/charge/'+batteryId)
    .then(response => {
        customFetch(dateRange[1], dateRange[1]);
        setRefresh();
        setIsLoading(false);
    }).catch('error: ' + console.log);
  }

  const dischargeBattery = async () => {
    setIsLoading(true);
    await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/battery/discharge/'+batteryId)
    .then(response => {
        customFetch(dateRange[1], dateRange[1]);
        setRefresh();
        setIsLoading(false);
    }).catch('error: ' + console.log);
  }

  const stopBattery = async () => {
    setIsLoading(true);
    await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/battery/stop/'+batteryId)
    .then(response => {
        customFetch(dateRange[1], dateRange[1]);
        setRefresh();
        setIsLoading(false);
    }).catch('error: ' + console.log);
  }

  const config = {
    data: [graphData, priceGraphData],
    xField: 'date',
    yField: ['value', 'value'],
    height: 300,
    tooltip: {
      formatter: (x) => {
        if (x.type === t("Prevádzka")) {
          return { name: x.type, value: x.value };
        } else if (x.type === t("page.price")) {
          return { name: x.type, value: parseFloat(x.value).toFixed(0) + ' €/MWh'};
        } else if (x.type === t("page.charge_state")) {
          return { name: x.type, value: parseFloat(x.value).toFixed(0) + ' %'};
        } else {
          return { name: x.type, value: parseFloat(x.value).toFixed(0) + ' €' };
        }
      },
    },
    legend: {
      position: 'top-left'
    },
    geometryOptions: [
      {
        color: ['#0ebf13', '#F4664A','#ff9f0a'],
        geometry: 'column',
        isGroup: true,
        seriesField: 'type',
      },
      {
        color: ['#5B8FF9','#FF0000'],
        geometry: 'line',
        seriesField: 'type',
        lineStyle: ({ type }) => {
          if (type === t("page.charge_state")) {
            return {
              lineDash: [1, 4],
              opacity: 1,
            };
          }

          return {
            lineWidth: 2,
            opacity: 0.5,
          };
        },
        label: {
          formatter: (x) => {
            if (x.type === t("page.charge_state")) {
              return parseFloat(x.value).toFixed(0) + ' %';
            } else {
              return parseFloat(x.value).toFixed(0)  + ' €';
            }
          },
        },  
        point: {
          size: 5,
        },
        interactions: [
          {
            type: 'marker-active',
          },
        ],
      },
    ],
  };

  return (
    <Spin spinning={isLoading}>
      <Card
        size="small" loading={isLoading}
        title={
          <>
            <Row gutter={[5,10]}>
              <Col span={6} xs={24} md={6} xl={6} xxl={6}>
                <DatePicker 
                  value={dateRange[1]}
                  onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                  style={{ width: '100%', marginRight: 10 }}
                  format="DD.MM.YYYY" 
                  maxDate={dayjs()}
                />
              </Col>
              <Col flex="auto">
                <Row justify="end">
                  <Col>
                    {keycloak.idTokenParsed.preferred_username === 'margorin@digma.sk' ?
                      <>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Popconfirm
                            title="Charge battery?"
                            description="Are you sure to charge this battery?"
                            onConfirm={chargeBattery}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button type="dashes" shape="circle" icon={<PlusOutlined />} />
                          </Popconfirm>
                        </Tooltip>
                      </Space>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Popconfirm
                            title="Discharge battery?"
                            description="Are you sure to discharge this battery?"
                            onConfirm={dischargeBattery}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button type="dashes" shape="circle" icon={<MinusOutlined />} />
                          </Popconfirm>
                        </Tooltip>
                      </Space>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Popconfirm
                            title="Stop battery?"
                            description="Are you sure to stop this battery?"
                            onConfirm={stopBattery}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button type="dashes" shape="circle" icon={<CloseOutlined />} />
                          </Popconfirm>
                        </Tooltip>
                      </Space>
                      <Space style={{ paddingRight: 5 }}>|</Space>
                      </>
                    : <></>}

                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
                      </Tooltip>
                    </Space>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
                      </Tooltip>
                    </Space>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<SyncOutlined />} onClick={refreshChart} />
                      </Tooltip>
                    </Space>
                    {/*
                    <Space style={{ paddingLeft: 10 }}>
                      <Tooltip title="Export" style={{ margin: 15 }} >
                        <Button type="dashes" icon={<DownloadOutlined />} />
                      </Tooltip>
                    </Space>
                    */}
                </Col>
              </Row>
            </Col>
          </Row>
        </>
        }>
        {emptyGraphData ?
          <Empty />
        :
          <ReactG2Plot Ctor={DualAxes} options={config} />
        }
      </Card>
    </Spin>
  );
};

export default BatteryControlChart;