import { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { Line } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';

import { useAxios } from '../../../util/AxiosUtil';
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';

const BatteryPowerChart = ({ batteryId, refresh }) => {

  const { i18n, t } = useTranslation();
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

  const [graphData, setGraphData] = useState([]);
  const [emptyGraphData, setEmptyGraphData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    customFetch();
  }, [batteryId, refresh]);

  const customFetch = async () => {
    setIsLoading(true);
    await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/battery/'+batteryId+'/batteryPower')
    .then(response => {
      setEmptyGraphData(false);
      if (response.data.length === 0)
        setEmptyGraphData(true);

      var myJson = [];
      for (var k = 0; k < response.data.length; k++) {
        if (response.data[k].value !== null) {
          myJson.push({
            value: response.data[k].value,
            type: response.data[k].name,
            date: dayjs(response.data[k].date).format("DD.MM.YYYY HH:mm")
          });
        }
      }

      setGraphData(myJson);
      setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  const config = {
    data: graphData,
    padding: 'auto',
    xField: 'date',
    yField: 'value',
    height: 150,
    color: '#0ebf13',
    tooltip: {
      formatter: (x) => {
        return { name: x.value > 0 ? t("page.charging") : t("page.discharging"), value: parseFloat(x.value).toFixed(0) + ' kW' };
      },
    },
    annotations: [
      {
        type: 'regionFilter',
        start: ['min', '0'],
        end: ['max', '-1000'],
        color: '#F4664A',
      },{
        type: 'line',
        start: ['min', '0'],
        end: ['max', '0'],
        style: {
          stroke: '#5B8FF9',
          lineDash: [2, 2],
        },
      },
    ],
    xAxis: {
      label: false
    }
  };

  return (
    <Spin spinning={isLoading}>
      {emptyGraphData ?
        <Empty style={{ height: 300 }}/>
      :
        <ReactG2Plot Ctor={Line} options={config} />
      }
    </Spin>
  );
};

export default BatteryPowerChart;