import { useState, useEffect } from 'react';
import { Tabs, Typography, Card, Row, Col, Space, Breadcrumb } from 'antd';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useAxios } from '../../util/AxiosUtil';
import { useTranslation } from "react-i18next";

import isInRole from '../../security/IsInRole';
import HeatPumpSensorMeasureLogCard from './measure/HeatPumpSensorMeasureLogCard';
import HeatPumpSensorConsumeLogCard from './consume/HeatPumpSensorConsumeLogCard';

import { HomeOutlined } from '@ant-design/icons';

const HeatPumpSensor = () => {
    
    let params = useParams();
    const { TabPane } = Tabs;
    const { Title } = Typography;

    const { i18n, t } = useTranslation();
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const navigate = useNavigate();

    const [sensorData, setSensorData] = useState([]);
    const [heatPumpData, setHeatPumpData] = useState([]);
    const [boilerRoomData, setBoilerRoomData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      customFetch();
    }, [params.heatPumpId]);

    const customFetch = async () => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/heatPumpSensor/'+params.heatPumpId+'/'+params.sensorId)
      .then(response => {
          setSensorData(response.data);
          setHeatPumpData(response.data.heatPump);
          setBoilerRoomData(response.data.heatPump.boilerRoom);
          setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    return (
      <>
        {isInRole(['enerpo_admin']) ?
            <></>
        : <></>}

        <Breadcrumb
          items={[
            { title: <Link to={process.env.REACT_APP_ENERPO_URL + "/"}><HomeOutlined /></Link>, },
            { title: <Link to={process.env.REACT_APP_ENERPO_URL + "/company/"+boilerRoomData.companyId}> {boilerRoomData.companyName}</Link>, },
            { title: <Link to={process.env.REACT_APP_ENERPO_URL + "/boilerRoom/"+boilerRoomData.id}>{boilerRoomData.name}</Link>, },
            { title: <Link to={process.env.REACT_APP_ENERPO_URL + "/heatPump/"+heatPumpData.id}>{heatPumpData.name}</Link>, },
            { title: t("page.sensor_consume")},
          ]}
        />

        <Row>
            <Col span={24} style={{ paddingTop: 10 }}>
                <Card bordered={true} styles={{body : {padding: 10}}} loading={isLoading}>
                  <Space style={{ paddingLeft: 2 }}>
                    <Title level={5}>
                      {t("page.sensor_consume") + ": " + sensorData.sensorName + " - " + heatPumpData.name}
                    </Title>
                  </Space>
                </Card>
            </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 10, marginBottom: 10 }}>
          <Col span={24}>
            <HeatPumpSensorMeasureLogCard sensorData={sensorData} loading={isLoading} />
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 10, marginBottom: 20 }}>
          <Col span={24}>
            <HeatPumpSensorConsumeLogCard sensorData={sensorData} loading={isLoading} />
          </Col>
        </Row>
      </>
    );
};

export default HeatPumpSensor;