import { useState, useEffect, useContext } from 'react';
import { Empty, DatePicker, Tooltip, Card, Button, Select, Space, Spin, Row, Col } from 'antd';
import { useAxios } from '../../../util/AxiosUtil';
import { useTranslation } from "react-i18next";
import { getStep, getDateFormat, getDispDateFormat, getMinutesPart, getDateRange, disabled3MonthsDate, disabled12MonthsDate } from '../../../util/ChartUtil';
import { LocaleContext } from '../../../context/LocaleContext';

import dayjs from 'dayjs';
import { DualAxes } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';

import { LeftOutlined, RightOutlined, DownloadOutlined, SyncOutlined } from '@ant-design/icons';

const TemperatureChart = ({ heatPumpId, boilerRoomId, unit, refresh, dateRange, changeDateRange, groupBy, changeGroupBy }) => {

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  const { RangePicker } = DatePicker;
  
  const { i18n, t } = useTranslation();
  const { locale, setLocale } = useContext(LocaleContext);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const [outTempData, setOutTempData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [emptyGraphData, setEmptyGraphData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    customFetch(dateRange[0], dateRange[1]);
  }, [heatPumpId, boilerRoomId, dateRange, unit, refresh, groupBy, locale]);

  useEffect(() => {
    function handleResize() {
        setIsMobile(window.innerWidth < 700)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  const customFetch = async (dateFrom, dateTo) => {

    setIsLoading(true);
    try {
      const avgTemp = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+boilerRoomId+'/BOILER_ROOM/avgOutTemp/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));
      const entryTemp = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+heatPumpId+'/HEAT_PUMP/entryTemp/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));
      const returnTemp = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+heatPumpId+'/HEAT_PUMP/returnTemp/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));

      var avgTempData = [];
      for (var k = 0; k < avgTemp.data.length; k++) {
        if (dayjs(avgTemp.data[k].date) < dayjs() && avgTemp.data[k].value !== null) {
//console.log(avgTemp.data[k].value);
          avgTempData.push({
            //Teplota: String(parseFloat(avgTemp.data[k].value).toFixed(2)),
            Teplota: avgTemp.data[k].value,
            type: t("Teplota vonkajšia"),
            date: dayjs(avgTemp.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy)
          });
        }
      }

      var entryTempData = [];
      for (var k = 0; k < entryTemp.data.length; k++) {
        if (entryTemp.data[k].value !== null) {
          entryTempData.push({
            value: entryTemp.data[k].value,
            type: t("Teplota výstup"),
            date: dayjs(entryTemp.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy)
          });
        }
      }

      var returnTempData = [];
      for (var k = 0; k < returnTemp.data.length; k++) {
        if (returnTemp.data[k].value !== null) {
          returnTempData.push({
            value: returnTemp.data[k].value,
            type: t("Teplota spiatočná"),
            date: dayjs(returnTemp.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy)
          });
        }
      }

      var finalData = entryTempData.concat(returnTempData);

      setEmptyGraphData(false);
      if (finalData.length === 0)
        setEmptyGraphData(true);

      setGraphData(finalData);
      setOutTempData(avgTempData);
      setIsLoading(false);
    } catch(err) {
      console.log(err);
    }
  };

  const prevStep = () => {
    changeDateRange([dayjs(dateRange[0]).subtract(1,getStep(groupBy)), dayjs(dateRange[1]).subtract(1,getStep(groupBy))]);
  }
  
  const nextStep = () => {
    if (!dayjs(dateRange[1]).isSame(dayjs())) {
      changeDateRange([dayjs(dateRange[0]).add(1,getStep(groupBy)), dayjs(dateRange[1]).add(1,getStep(groupBy))]);
    } else {
      fetchData(dateRange[0], dateRange[1]);
    }
  }

  const refreshChart = () => {
    customFetch(dateRange[0], dateRange[1]);
  }

  const getChartUnit = (x) => {

    if (x.Teplota) {
      return { name: t("Teplota vonkajšia"), value: parseFloat(x.Teplota).toFixed(2) + ' °C'};
    } else {
      return { name: x.type, value: parseFloat(x.value).toFixed(2) + ' °C'};
    }
  }

  const config = {
    data: [graphData, outTempData],
    xField: 'date',
    yField: ['value', 'Teplota'],
    height: 300,
    tooltip: {
      formatter: (x) => {
        return getChartUnit(x);
      },
    },
    geometryOptions: [
      {
        geometry: 'column',
        isGroup: true,
        seriesField: 'type',
      },
      {
        geometry: 'line',
        seriesField: 'type',
        lineStyle: {
          lineWidth: 2,
        },
      },
    ],
  };

  return (
    <Spin spinning={isLoading}>
      <Card
        size="small" loading={isLoading}
        title={
          <>
            <Row gutter={[5,10]}>
              <Col span={3} xs={24} md={3} xl={3} xxl={3}>
                <Select
                  onChange={changeGroupBy} 
                  defaultValue={{ value: groupBy }}
                  style={{ width: '100%', marginRight: 10 }}
                  options={[ { value: 'hour', label: "1 " + t("page.hour") }, { value: 'day', label: "1 " + t("page.day") }, { value: 'month', label: "1 " + t('page.month') } ]}
                />
              </Col>
              <Col span={6} xs={24} md={6} xl={6} xxl={6}>
                {groupBy === 'hour'?
                  <DatePicker 
                    value={dateRange[0]}
                    onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                    style={{ width: '100%', marginRight: 10 }}
                    format="DD.MM.YYYY" 
                    maxDate={dayjs()}
                  />
                : groupBy === 'day' ?
                  <RangePicker 
                    value={dateRange}
                    onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                    style={{ width: '100%', marginRight: 10 }}
                    format={"DD.MM.YYYY"}
                    maxDate={dayjs()}
                    disabledDate={disabled3MonthsDate}
                  />
                :
                  <RangePicker
                    value={dateRange}
                    onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                    style={{ width: '100%', marginRight: 10 }}
                    format={"MM.YYYY"} 
                    picker={"month"}
                    maxDate={dayjs()}
                    disabledDate={disabled12MonthsDate}
                  />
                }
              </Col>
              <Col flex="auto">
                <Row justify="end">
                  <Col>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
                      </Tooltip>
                    </Space>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
                      </Tooltip>
                    </Space>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<SyncOutlined />} onClick={refreshChart} />
                      </Tooltip>
                    </Space>
                    {/*
                    <Space style={{ paddingLeft: 10 }}>
                      <Tooltip title="Export" style={{ margin: 15 }} >
                        <Button type="dashes" icon={<DownloadOutlined />} />
                      </Tooltip>
                    </Space>
                    */}
                </Col>
              </Row>
            </Col>
          </Row>
        </>
        }>
        {emptyGraphData ?
          <Empty />
        :
          <ReactG2Plot Ctor={DualAxes} options={config} />
        }
      </Card>
    </Spin>
  );
};

export default TemperatureChart;