import { Layout } from 'antd';
import { EnerpoHeader } from './EnerpoHeader';
import { EnerpoMenu } from './EnerpoMenu';
import { Routes, Route } from 'react-router-dom';

import isInRole from '../security/IsInRole';
import Unauthorized from '../template/error/Unauthorized';

import Company from '../template/company/Company';
import CompanyList from '../template/company/CompanyList';
import BoilerRoom from '../template/boiler_room/BoilerRoom';
import BoilerRoomList from '../template/boiler_room/BoilerRoomList';
import Boiler from '../template/boiler/Boiler';
import Branch from '../template/branch/Branch';
import BoilerRoomSensor from '../template/boiler_room_sensor/BoilerRoomSensor';
import HeatPumpSensor from '../template/heat_pump_sensor/HeatPumpSensor';
import BranchSensor from '../template/branch_sensor/BranchSensor';
import ThermoSensor from '../template/thermo_sensor/ThermoSensor';
import Fwe from '../template/fwe/Fwe';
import FweList from '../template/fwe/FweList';
import Battery from '../template/battery/Battery';
import BatteryList from '../template/battery/BatteryList';
import HeatPump from '../template/heat_pump/HeatPump';
import HeatPumpList from '../template/heat_pump/HeatPumpList';

import { useKeycloak } from '@react-keycloak/web';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_MCS_API_GATEWAY_URL
});

const { Content } = Layout;

const AppLayout = () => {
  
  const { keycloak } = useKeycloak();

  return (
    <Layout>
        {isInRole(['enerpo_web']) ? 
          <Layout>
            <EnerpoHeader firstname={keycloak.idTokenParsed.given_name} lastname={keycloak.idTokenParsed.family_name} />
            <Layout>
              <Layout.Sider
                  style={{ marginTop: 64 }}
                  breakpoint={"lg"}
                  theme="light"
                  collapsedWidth={0}
                  trigger={null}
                >
                  <EnerpoMenu />
              </Layout.Sider>
              <Layout>
                <Content>
                  <div style={{ minHeight: '100vh', paddingTop: 80, paddingLeft: 20, paddingRight: 20 }}>
                    <Routes>
                      {/* 
                      <Route path={process.env.REACT_APP_ENERPO_URL} element={<Dashboard />} exact />
                      <Route path={process.env.REACT_APP_ENERPO_URL + "/dashboard"} element={<Dashboard />} />
                      */}

                      <Route path={process.env.REACT_APP_ENERPO_URL} element={<CompanyList key={Date.now()} />} exact />

                      <Route path={process.env.REACT_APP_ENERPO_URL + "/company/:id"} element={<Company />} />
                      <Route path={process.env.REACT_APP_ENERPO_URL + "/company"} element={<CompanyList key={Date.now()} />} />

                      <Route path={process.env.REACT_APP_ENERPO_URL + "/boilerRoom/:id"} element={<BoilerRoom />} />
                      <Route path={process.env.REACT_APP_ENERPO_URL + "/boilerRoom"} element={<BoilerRoomList key={Date.now()} />} />

                      <Route path={process.env.REACT_APP_ENERPO_URL + "/heatPump/:id"} element={<HeatPump />} />
                      <Route path={process.env.REACT_APP_ENERPO_URL + "/heatPump"} element={<HeatPumpList key={Date.now()} />} />

                      <Route path={process.env.REACT_APP_ENERPO_URL + "/fwe/:id"} element={<Fwe />} />
                      <Route path={process.env.REACT_APP_ENERPO_URL + "/fwe"} element={<FweList key={Date.now()} />} />

                      <Route path={process.env.REACT_APP_ENERPO_URL + "/battery/:id"} element={<Battery />} />
                      <Route path={process.env.REACT_APP_ENERPO_URL + "/battery"} element={<BatteryList key={Date.now()} />} />

                      <Route path={process.env.REACT_APP_ENERPO_URL + "/boiler/:boilerRoomId/:id"} element={<Boiler />} />
                      <Route path={process.env.REACT_APP_ENERPO_URL + "/branch/:boilerRoomId/:id"} element={<Branch />} />

                      <Route path={process.env.REACT_APP_ENERPO_URL + "/boilerRoomSensor/:boilerRoomId/:sensorId"} element={<BoilerRoomSensor />} />
                      <Route path={process.env.REACT_APP_ENERPO_URL + "/branchSensor/:branchId/:sensorId"} element={<BranchSensor />} />
                      <Route path={process.env.REACT_APP_ENERPO_URL + "/heatPumpSensor/:heatPumpId/:sensorId"} element={<HeatPumpSensor />} />
                      <Route path={process.env.REACT_APP_ENERPO_URL + "/thermoSensor/:boilerRoomId/:sensorId"} element={<ThermoSensor />} />
                    </Routes>
                  </div>
                </Content>
              </Layout>
            </Layout>
          </Layout>
        : <Unauthorized />}
    </Layout>
    
  );
}

export default AppLayout;