import { useState } from 'react';

import keycloak from './keycloak'
import { ReactKeycloakProvider } from '@react-keycloak/web';
import AppLayout from './components/layout/AppLayout';
import Spinner from './components/layout/Spinner';

import { LocaleContext } from './components/context/LocaleContext';
import { ConfigProvider } from 'antd';
import sk from 'antd/locale/sk_SK';
import it from 'antd/locale/it_IT';
import en from 'antd/locale/en_US';

import "./components/i18/i18n";

const keycloakProviderInitConfig = {
  onLoad: 'login-required',
  //checkLoginIframe: false
}

const App = () => {

  const [locale, setLocale] = useState('en');

  const getAntdLocale = () => {
    return locale === 'sk' ? sk :
           locale === 'it' ? it : en;
  }

  return (
    <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakProviderInitConfig} LoadingComponent={<Spinner/>}>
      <LocaleContext.Provider value={{locale, setLocale}}>
        <ConfigProvider locale={getAntdLocale()}>
          <AppLayout />
        </ConfigProvider>
      </LocaleContext.Provider>
    </ReactKeycloakProvider>
  );
}

export default App;