import { useState, useEffect } from 'react';
import { Empty, DatePicker, Tooltip, Card, Button, Select, Space, Spin, Row, Col } from 'antd';
import { useAxios } from '../../../util/AxiosUtil';
import { useTranslation } from "react-i18next";
import { getStep, getDateFormat, getDispDateFormat, getMinutesPart, getDateRange, disabled3MonthsDate, disabled12MonthsDate } from '../../../util/ChartUtil';

import dayjs from 'dayjs';
import { Column, DualAxes } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';

import { LeftOutlined, RightOutlined, DownloadOutlined, SyncOutlined } from '@ant-design/icons';

const BatteryChargingChart = ({ batteryId, unit, refresh, setRefresh, dateRange, changeDateRange }) => {

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  const { RangePicker } = DatePicker;
  
  const { i18n, t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const [graphData, setGraphData] = useState([]);
  const [cashflowGraphData, setCashflowGraphData] = useState([]);
  const [emptyGraphData, setEmptyGraphData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [graphType, setGraphType] = useState("column");
  const [groupBy, setGroupBy] = useState("day");

  useEffect(() => {
    (groupBy === 'hour') ? 
      customFetch(dateRange[1], dateRange[1])
    : customFetch(dateRange[0], dateRange[1]);
  }, [batteryId, dateRange, unit, refresh, groupBy, t]);

  useEffect(() => {
    function handleResize() {
        setIsMobile(window.innerWidth < 700)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  const customFetch = async (dateFrom, dateTo) => {
    
    setIsLoading(true);

    try {
      const charging = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+batteryId+'/BATTERY/charging/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));
      const discharging = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+batteryId+'/BATTERY/discharging/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));

      //// pocet cyklov + cashflow - iba ak groupBy != hour
      const batteryAction = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+batteryId+'/BATTERY/battery_action/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));
      const cashflow = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/battery/'+batteryId+'/cashflow/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));

      const cycleDataReduced = batteryAction.data.reduce((acc, {date, value}) => {
        let dt = dayjs(date).format(getDispDateFormat(groupBy));
        acc[dt] ??= {c: 0, d: 0};
        for (var k = 0; k < cashflow.data.length; k++) {
          if (dayjs(cashflow.data[k].date).format(getDispDateFormat(groupBy)) === dt) {            
            if (value === 1) acc[dt].c += 1;
            if (value === 2) acc[dt].d += 1;
          }
        }
        return acc;
      }, {});

      var chargingCycleData = [];
      var dischargingCycleData = [];
      for (const [key, value] of Object.entries(cycleDataReduced)) {
        chargingCycleData.push({
          value: value.c / 2,
          type: t("page.charging_cycle"),
          bar_type: 'charging',
          date: key
        });

        dischargingCycleData.push({
          value: value.d / 2,
          type: t("page.discharging_cycle"),
          bar_type: 'discharging',
          date: key
        });
      }

      var chargingData = [];
      for (var k = 0; k < charging.data.length; k++) {
        if (charging.data[k].value !== null) {
          chargingData.push({
            value: unit === 'kWh' ? charging.data[k].value : charging.data[k].value / 1000,
            type: t("page.charging"),
            bar_type: 'charging',
            date: dayjs(charging.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy)
          });
        }
      }
      
      var dischargingData = [];
      for (var k = 0; k < discharging.data.length; k++) {
        if (discharging.data[k].value !== null) {
          dischargingData.push({
            value: unit === 'kWh' ? discharging.data[k].value : discharging.data[k].value / 1000,
            type: t("page.discharging"),
            bar_type: 'discharging',
            date: dayjs(discharging.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy)
          });
        }
      }

      var finalData = chargingData.concat(dischargingData);
      if (groupBy !== 'hour')
        finalData = finalData.concat(chargingCycleData).concat(dischargingCycleData);
      
      var cashflowData = [];
      for (var k = 0; k < cashflow.data.length; k++) {
        cashflowData.push({
          value: parseFloat(cashflow.data[k].value),
          date: dayjs(cashflow.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy),
          type: t("page.cashflow")
        });
      }
      setCashflowGraphData(cashflowData);

      setEmptyGraphData(false);
      if (finalData.length === 0)
        setEmptyGraphData(true);

      setGraphData(finalData);
      setIsLoading(false);

    } catch(err) {
      console.log(err);
    }
  };

  const changeGroupBy = (value) => {
    setGroupBy(value);
    value === 'hour' ? changeDateRange([dateRange[1], dateRange[1]]) 
    : value === 'day' ? changeDateRange([dateRange[1].subtract(14, 'd'), dateRange[1]])
    : changeDateRange([dateRange[1].subtract(4, 'M'), dateRange[1]]);
  }

  const prevStep = () => {
    changeDateRange([dayjs(dateRange[0]).subtract(1,getStep(groupBy)), dayjs(dateRange[1]).subtract(1,getStep(groupBy))]);
  }
  
  const nextStep = () => {
    if (!dayjs(dateRange[1]).isSame(dayjs())) {
      changeDateRange([dayjs(dateRange[0]).add(1,getStep(groupBy)), dayjs(dateRange[1]).add(1,getStep(groupBy))]);
    } else {
      (groupBy === 'hour') ? 
        customFetch(dateRange[1], dateRange[1])
      : customFetch(dateRange[0], dateRange[1]);
    }
  }

  const refreshChart = () => {
    (groupBy === 'hour') ? 
      customFetch(dateRange[1], dateRange[1])
    : customFetch(dateRange[0], dateRange[1]);
    setRefresh();
  }

  const hConfig = {
    data: graphData,
    isGroup: true,
    seriesField: 'type',
    xField: 'date',
    yField: 'value',
    height: 300,
    color: ['#0ebf13','#F4664A'],
    tooltip: {
      formatter: (x) => {
          return { 
            name: x.type,
            value: (unit === 'kWh' ? parseFloat(x.value).toFixed(0) + ' kWh' : parseFloat(x.value).toFixed(0) + ' MWh')
          };
      },
    },
    legend: false
  };

  const config = {
    data: [graphData, cashflowGraphData],
    xField: 'date',
    yField: ['value', 'value'],
    height: 300,
    tooltip: {
      formatter: (x) => {
        if (x.type === t("page.cashflow")) {
          return { name: x.type, value: parseFloat(x.value).toFixed(0) + ' €'};
        } else if (x.type === t("page.charging") || x.type === t("page.discharging")) {
          return { name: x.type, value: (unit === 'kWh' ? parseFloat(x.value).toFixed(0) + ' kWh' : parseFloat(x.value).toFixed(0) + ' MWh') };
        } else {
          return { name: x.type, value: x.value };
        }
      },
    },
    legend: {
      position: 'top-left'
    },
    geometryOptions: [
      {
        color: ['#0ebf13', '#F4664A', '#2aef30', '#ff9f0a'],
        geometry: 'column',
        isGroup: true,
        isStack: true,
        seriesField: 'type',
        groupField: 'bar_type',
      },
      {
        color: ['#5B8FF9'],
        geometry: 'line',
        seriesField: 'type',
        label: {
          formatter: (x) => {
            return parseFloat(x.value).toFixed(0)  + ' €';
          },
        },
      },
    ],
  };

  return (
    <Spin spinning={isLoading}>
      <Card
        size="small" loading={isLoading}
        title={
          <>
            <Row gutter={[5,10]}>
              <Col span={3} xs={24} md={3} xl={3} xxl={3}>
                <Select
                  onChange={changeGroupBy} 
                  defaultValue={{ value: groupBy }}
                  style={{ width: '100%', marginRight: 10 }}
                  options={[ { value: 'hour', label: "1 " + t("page.hour") }, { value: 'day', label: "1 " + t("page.day") }, { value: 'month', label: "1 " + t('page.month') } ]}
                />
              </Col>
              <Col span={6} xs={24} md={6} xl={6} xxl={6}>
                {groupBy === 'hour'?
                  <DatePicker 
                    value={dateRange[1]}
                    onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                    style={{ width: '100%', marginRight: 10 }}
                    format="DD.MM.YYYY" 
                    maxDate={dayjs()}
                  />
                : groupBy === 'day' ?
                  <RangePicker 
                    value={dateRange}
                    onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                    style={{ width: '100%', marginRight: 10 }}
                    format={"DD.MM.YYYY"}
                    maxDate={dayjs()}
                    disabledDate={disabled3MonthsDate}
                  />
                :
                  <RangePicker
                    value={dateRange}
                    onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                    style={{ width: '100%', marginRight: 10 }}
                    format={"MM.YYYY"} 
                    picker={"month"}
                    maxDate={dayjs()}
                    disabledDate={disabled12MonthsDate}
                  />
                }
              </Col>
              <Col flex="auto">
                <Row justify="end">
                  <Col>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
                      </Tooltip>
                    </Space>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
                      </Tooltip>
                    </Space>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<SyncOutlined />} onClick={refreshChart} />
                      </Tooltip>
                    </Space>
                    {/*
                    <Space style={{ paddingLeft: 10 }}>
                      <Tooltip title="Export" style={{ margin: 15 }} >
                        <Button type="dashes" icon={<DownloadOutlined />} />
                      </Tooltip>
                    </Space>
                    */}
                </Col>
              </Row>
            </Col>
          </Row>
        </>
        }>
        {emptyGraphData ?
          <Empty />
        :
          (groupBy === 'hour') ? 
            <ReactG2Plot Ctor={Column} options={hConfig} />
          : <ReactG2Plot Ctor={DualAxes} options={config} />
        }
      </Card>
    </Spin>
  );
};

export default BatteryChargingChart;