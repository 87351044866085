import { useState, useEffect, useContext } from 'react';
import { Empty, DatePicker, Tooltip, Card, Button, Select, Space, Spin, Row, Col } from 'antd';
import { useAxios } from '../../../util/AxiosUtil';
import { useTranslation } from "react-i18next";
import { LocaleContext } from '../../../context/LocaleContext';

import { getStep, getDateFormat, getDispDateFormat, getMinutesPart, getDateRange, disabled3MonthsDate, disabled12MonthsDate } from '../../../util/ChartUtil';

import dayjs from 'dayjs';
import { DualAxes } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';

import { LeftOutlined, RightOutlined, DownloadOutlined, SyncOutlined } from '@ant-design/icons';

const FweRevenueChart = ({ fweId, unit, refresh, dateRange, changeDateRange, groupBy, changeGroupBy }) => {

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  const { RangePicker } = DatePicker;
  
  const { i18n, t } = useTranslation();
  const { locale, setLocale } = useContext(LocaleContext);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const [graphData, setGraphData] = useState([]);
  const [spotGraphData, setSpotGraphData] = useState([]);
  const [emptyGraphData, setEmptyGraphData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    customFetch(dateRange[0], dateRange[1]);
  }, [fweId, dateRange, unit, refresh, groupBy, locale]);

  useEffect(() => {
    function handleResize() {
        setIsMobile(window.innerWidth < 700)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  const customFetch = async (dateFrom, dateTo) => {

    setIsLoading(true);
    try {
      const fweProductionData = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+fweId+'/FWE/production/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));
      const revenueData = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+fweId+'/FWE/revenue/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));
      const taxData = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+fweId+'/FWE/sppTax/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));
      const nuclearFundData = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+fweId+'/FWE/nuclearFund/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));
      const eeSpotData = await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/okte/electricity/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)));

      // spotrebna dan
      var taxDataJson = [];
      for (var k = 0; k < fweProductionData.data.length; k++) {
        for (var l = 0; l < taxData.data.length; l++) {
          if (dayjs(fweProductionData.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy) === 
              dayjs(taxData.data[l].date).format(getDispDateFormat(groupBy) + getMinutesPart(groupBy))) {
            taxDataJson.push({
              value: taxData.data[l].value * fweProductionData.data[k].value,
              type: t("Spotrebná daň"),
              date: dayjs(fweProductionData.data[k].date).format(getDispDateFormat(groupBy) + getMinutesPart(groupBy))
            });
          }
        }
      }

      // odvod do jadroveho fondu
      var nuclearFundDataJson = [];
      for (var k = 0; k < fweProductionData.data.length; k++) {
        for (var l = 0; l < nuclearFundData.data.length; l++) {
          if (dayjs(fweProductionData.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy) === 
              dayjs(nuclearFundData.data[l].date).format(getDispDateFormat(groupBy) + getMinutesPart(groupBy))) {
            nuclearFundDataJson.push({
              value: nuclearFundData.data[l].value * fweProductionData.data[k].value,
              type: t("Odvod do jadrového fondu"),
              date: dayjs(fweProductionData.data[k].date).format(getDispDateFormat(groupBy) + getMinutesPart(groupBy))
            });
          }
        }
      }

      // ee spot prices
      var eeSpotDataJson = [];
      if (groupBy === 'hour') {
        for (var k = 0; k < eeSpotData.data.length; k++) {
          for (var l = 0; l < nuclearFundDataJson.length; l++) {
            if (dayjs(eeSpotData.data[k].bday + ' ' + eeSpotData.data[k].period).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy) === nuclearFundDataJson[l].date) {
              eeSpotDataJson.push({
                price: parseFloat(eeSpotData.data[k].price),
                type: t("page.price"),
                date: nuclearFundDataJson[l].date
              });
            }
          }
        }
      }

      var sumRevenueDataJson = [];
      for (var k = 0; k < revenueData.data.length; k++) {
        for (var l = 0; l < taxDataJson.length; l++) {

          if (dayjs(revenueData.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy) === taxDataJson[l].date) {
            for (var v = 0; v < nuclearFundDataJson.length; v++) {
              if (taxDataJson[l].date === nuclearFundDataJson[v].date) {
                sumRevenueDataJson.push({
                  value: revenueData.data[k].value + taxDataJson[l].value + nuclearFundDataJson[v].value,
                  type: t("Výnos spolu"),
                  date: nuclearFundDataJson[l].date
                });
              }
            }
          }
        }
      }

      var finalGraphdata = taxDataJson.concat(nuclearFundDataJson).concat(sumRevenueDataJson);
      setGraphData(finalGraphdata);
      setSpotGraphData(eeSpotDataJson);

      setEmptyGraphData(false);
      if (finalGraphdata.length === 0)
        setEmptyGraphData(true);

      setIsLoading(false);
    } catch(err) {
      console.log(err);
    }
  }

  const prevStep = () => {
    changeDateRange([dayjs(dateRange[0]).subtract(1,getStep(groupBy)), dayjs(dateRange[1]).subtract(1,getStep(groupBy))]);
  }
  
  const nextStep = () => {
    if (!dayjs(dateRange[1]).isSame(dayjs())) {
      changeDateRange([dayjs(dateRange[0]).add(1,getStep(groupBy)), dayjs(dateRange[1]).add(1,getStep(groupBy))]);
    } else {
      fetchData(dateRange[0], dateRange[1]);
    }
  }

  const refreshChart = () => {
    customFetch(dateRange[0], dateRange[1]);
  }

  const getChartUnit = (x) => {
    if (x.price !== undefined) {
      return { name: t("Cena na spote"), value: x.price + ' Eur'};
    } else {
      return { name: x.type, value: parseFloat(x.value).toFixed(2) + ' Eur'};
    }
  }

  const getChartMarkers = () => {

    let markers = [];
    markers.push({
      name: t("Spotrebná daň"), 
      marker: {style: { fill: '#ea6c5c' }},
    });

    markers.push({
      name: t("Odvod do jadrového fondu"), 
      marker: {style: { fill: '#5B8FF9' }},
    });

    markers.push({
      name: t("Výnos spolu"), 
      marker: {style: { fill: '#5AD8A6' }},
    });

    if (groupBy === 'hour') {
      markers.push({
        name: t("Cena na spote"), 
        marker: {style: { fill: '#f7c122' }},
      });
    }

    return markers;
  }

  const config = {
    data: [graphData, spotGraphData],
    xField: 'date',
    yField: ['value', 'price'],
    height: 300,
    tooltip: {
      formatter: (x) => {
        return getChartUnit(x);
      },
    },
    legend: {
      custom: true,
      items: getChartMarkers()
    },
    geometryOptions: [
      {
        geometry: 'column',
        isStack: true,
        seriesField: 'type',
        color: ['#ea6c5c', '#5B8FF9', '#5AD8A6'],
      },
      {
        geometry: 'line',
        color: '#f7c122',
      },
    ],
  };

  return (
    <Spin spinning={isLoading}>
      <Card
        size="small" loading={isLoading}
        title={
          <>
            <Row gutter={[5,10]}>
              <Col span={3} xs={24} md={3} xl={3} xxl={3}>
                <Select
                  onChange={changeGroupBy} 
                  defaultValue={{ value: groupBy }}
                  style={{ width: '100%', marginRight: 10 }}
                  options={[ { value: 'hour', label: "1 " + t("page.hour") }, { value: 'day', label: "1 " + t("page.day") }, { value: 'month', label: "1 " + t('page.month') } ]}
                />
              </Col>
              <Col span={6} xs={24} md={6} xl={6} xxl={6}>
                {groupBy === 'hour'?
                  <DatePicker 
                    value={dateRange[0]}
                    onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                    style={{ width: '100%', marginRight: 10 }}
                    format="DD.MM.YYYY" 
                    maxDate={dayjs()}
                  />
                : groupBy === 'day' ?
                  <RangePicker 
                    value={dateRange}
                    onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                    style={{ width: '100%', marginRight: 10 }}
                    format={"DD.MM.YYYY"}
                    maxDate={dayjs()}
                    disabledDate={disabled3MonthsDate}
                  />
                :
                  <RangePicker
                    value={dateRange}
                    onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                    style={{ width: '100%', marginRight: 10 }}
                    format={"MM.YYYY"} 
                    picker={"month"}
                    maxDate={dayjs()}
                    disabledDate={disabled12MonthsDate}
                  />
                }
              </Col>
              <Col flex="auto">
                <Row justify="end">
                  <Col>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
                      </Tooltip>
                    </Space>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
                      </Tooltip>
                    </Space>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<SyncOutlined />} onClick={refreshChart} />
                      </Tooltip>
                    </Space>
                    {/*
                    <Space style={{ paddingLeft: 10 }}>
                      <Tooltip title="Export" style={{ margin: 15 }} >
                        <Button type="dashes" icon={<DownloadOutlined />} />
                      </Tooltip>
                    </Space>
                    */}
                </Col>
              </Row>
            </Col>
          </Row>
        </>
        }>
        {emptyGraphData ?
          <Empty />
        :
          <ReactG2Plot Ctor={DualAxes} options={config} />
        }
      </Card>
    </Spin>
  );
};

export default FweRevenueChart;