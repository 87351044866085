import { useState, useEffect, useContext } from 'react';
import { Row, Col, DatePicker, Empty, Spin, Tooltip, Button, Card, Space } from 'antd';
import { useAxios } from '../../../../../util/AxiosUtil';
import dayjs from 'dayjs';
import { Line } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';

import { getStep } from '../../../../../util/ChartUtil';
import { useTranslation } from "react-i18next";
import { LocaleContext } from '../../../../../context/LocaleContext';

import { LeftOutlined, RightOutlined, SyncOutlined } from '@ant-design/icons';

const ElectricityTab = ({ refresh, setRefresh, dateRange, changeDateRange }) => {
    
    const { i18n, t } = useTranslation();
    const { locale, setLocale } = useContext(LocaleContext);

    const dateFormat = "YYYY-MM-DD";
    const dateFormatDisp = "DD.MM.YYYY HH:ss";
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    
    const [groupBy, setGroupBy] = useState("day");
    const [graphData, setGraphData] = useState([]);
    const [emptyGraphData, setEmptyGraphData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      customFetch(dateRange[1]);
    }, [dateRange, refresh, locale]);
    
    const customFetch = async (startDate) => {
        setIsLoading(true);
        await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/okte/electricity/'+startDate.format(dateFormat))
        .then(response => {

          setEmptyGraphData(false);
          if (response.data.length === 0)
            setEmptyGraphData(true);

          var myJson = [];
          for (var k = 0; k < response.data.length; k++) {
            var period = response.data[k].period === '24' ? '00' : response.data[k].period;

            if (response.data[k].value !== null) {
              myJson.push({
                value: parseFloat(response.data[k].price),
                date: dayjs(response.data[k].bday+' '+period).format(dateFormatDisp)
              });
            }
          }

          setGraphData(myJson);
          setIsLoading(false);
        }).catch('error: ' + console.log);
    };

    function onDateChange(date) {
  
      if (date !== null) {
        var changedDateFrom = dayjs(date);
        var changedDateTo = dayjs(date);
  
        changeDateRange([changedDateFrom, changedDateTo]);
      }
    }

    const prevStep = () => {
      changeDateRange([dayjs(dateRange[0]).subtract(1,getStep(groupBy)), dayjs(dateRange[1]).subtract(1,getStep(groupBy))]);
    }
    
    const nextStep = () => {
      if (!dayjs(dateRange[1]).add(1,'d').isAfter(dayjs()) && !dayjs(dateRange[1]).isSame(dayjs())) {
        changeDateRange([dayjs(dateRange[0]).add(1,getStep(groupBy)), dayjs(dateRange[1]).add(1,getStep(groupBy))]);
      } else {
        customFetch(dateRange[1]);
      }
    }

    const refreshChart = () => {
      customFetch(dateRange[1]);
      if (setRefresh != null) setRefresh();
    }

    const config = {
      data: graphData,
      xField: 'date',
      yField: 'value',
      height: 300,
      label: {},
      point: {
        size: 5,
        shape: 'diamond',
        style: {
          fill: 'white',
          stroke: '#5B8FF9',
          lineWidth: 2,
        },
      },
      tooltip: {
        formatter: (x) => {
          return { name: t("page.price"), value: x.value + ' €/MWh'};
        },
      },
      state: {
        active: {
          style: {
            shadowBlur: 4,
            stroke: '#000',
            fill: 'red',
          },
        },
      },
    };

    return (
      <Spin spinning={isLoading}>
        <Card
          size="small" loading={isLoading}
          style={{ marginTop: 10, paddingBottom: 15 }}
          title={
            <>
              <Row gutter={[5,10]}>
                <Col span={6} xs={24} md={6} xl={6} xxl={6}>
                  <DatePicker
                    value={dateRange[1]}
                    onChange={onDateChange}
                    style={{ width: '100%', marginRight: 10 }}
                    format="DD.MM.YYYY" 
                  />
                </Col>
                <Col flex="auto">
                  <Row justify="end">
                    <Col>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
                        </Tooltip>
                      </Space>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
                        </Tooltip>
                      </Space>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Button type="dashes" shape="circle" icon={<SyncOutlined />} onClick={refreshChart} />
                        </Tooltip>
                      </Space>
                      {/*
                      <Space style={{ paddingLeft: 10 }}>
                        <Tooltip title="Export" style={{ margin: 15 }} >
                          <Button type="dashes" icon={<DownloadOutlined />} />
                        </Tooltip>
                      </Space>
                      */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
          }>
          {emptyGraphData ?
            <Empty />
          :
            <ReactG2Plot Ctor={Line} options={config} />
          }
        </Card>
      </Spin>
    );
};

export default ElectricityTab;